import api from '@/services/api';

const url = 'gallery';

const getGalleries = () => api.get(url);

const createGallery = (gallery) => api.post(url, gallery);

// eslint-disable-next-line max-len
const updateGallery = (gallery) => api.put(url.concat(`/${gallery.id}`),
  { name: gallery.name, photos: gallery.photos, galleryPhotoDeleted: gallery.galleryPhotoDeleted });

const deleteGallery = (id) => api.delete(url.concat(`/${id}`));

export default {
  getGalleries, createGallery, updateGallery, deleteGallery,
};
