<template>
  <div class="pa-6 pt-10">
    <v-data-table
      :headers="headers"
      :items="galleries"
      :search="search"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Galerias</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />

          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details
          />

          <v-spacer />

          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Adicionar
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              @click="openDialogDelete(item)"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
      <template #item.createdAt="{ item }">
        <p>{{ item.createdAt | parseDate }}</p>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
    >
      <form-gallery
        v-if="dialog"
        :populate-with="galleryToEdit"
        @gallery-added="addGallery"
        @gallery-edited="editGallery"
        @close-dialog="closeDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Cuidado!
        </v-card-title>

        <v-card-text>
          Você deseja realmente deletar esta galeria?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red darken-1"
            text
            @click="dialogDelete = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="deleteItem"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GalleryService from '../../../services/gallery.service';

export default {
  name: 'Gallery',
  components: {
    'form-gallery': () => import('./Form'),
  },
  data: () => ({
    headers: [
      {
        text: 'Nome',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      { text: 'Data de Criação', value: 'createdAt', sortable: false },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    galleries: [],
    search: '',
    dialog: false,
    dialogDelete: false,
    galleryToEdit: {},
    galleryToDelete: {},
  }),

  async created() {
    await this.loadGalleries();
  },

  methods: {
    async loadGalleries() {
      const data = await GalleryService.getGalleries();
      this.galleries = data;
    },

    async closeDialog() {
      this.dialog = false;
      this.galleryToEdit = {};
      await this.loadGalleries();
    },

    editItem(item) {
      this.galleryToEdit = item;
      this.dialog = true;
    },

    openDialogDelete(item) {
      this.galleryToDelete = item;
      this.dialogDelete = true;
    },

    async deleteItem() {
      try {
        this.dialogDelete = false;
        const itemId = this.galleryToDelete.id;
        await GalleryService.deleteGallery(itemId);
        this.$toast.success('Galeria excluída com sucesso');
        const index = this.galleries.findIndex((listGallery) => listGallery.id === this.galleryToDelete.id);
        this.galleries.splice(index, 1);
      } catch (e) {
        this.$handleHttpError(e);
      }
    },

    addGallery(gallery) {
      this.galleries.push(gallery);
    },

    editGallery(gallery) {
      const index = this.galleries.findIndex((listGallery) => listGallery.id === gallery.id);
      this.galleries[index] = gallery;
    },
  },
};
</script>
